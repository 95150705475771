<template>
  <header>
    <h1>
      <router-link to="/">Spicy Deluxe</router-link>
    </h1>
    <nav>
      <router-link to="/press">Press</router-link>
    </nav>
  </header>
  <router-view/>
</template>

<style lang="scss">
body {
  box-sizing: border-box;
  margin: 0;
  position: relative;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

header {
  background-color: transparent;
  padding: 30px;
  position: absolute;
  width: calc(100% - 60px);
  z-index: 2;

  h1 a {
    color: white;
    font-weight: bold;
    text-decoration: none;
  }

  nav {
    left: 60px;
    position: absolute;
    top: 60px;

    a {
      color: rgba(255, 255, 255, 0.8);
      font-weight: 600;
      margin-right: 25px;
      text-decoration: none;

      &:hover {
        color: rgba(255, 255, 255, 0.9);
      }
    }
  }
}

@media (max-width: 600px) {
  header nav {
    left: 0;
    text-align: center;
    top: 110px;
    width: 100%;

    a {
      margin-right: 0;
      text-decoration: underline;
    }
  }
}
</style>
