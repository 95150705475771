<template>
  <div class="section sectionDark upcomingShows">
    <div class="sectionContent">
      <h1>Upcoming Shows</h1>
      <div class="showContainer">
        <div class="show">
          <div class="showDate">Thu, August 11</div>
          <div class="showName">Rosetta Hall</div>
          <div class="showLocation">Boulder, CO</div>
          <div class="showTime">10pm - 1am</div>
        </div>
        <div class="show">
          <div class="showDate">Fri, August 26</div>
          <div class="showName">Rosetta Hall</div>
          <div class="showLocation">Boulder, CO</div>
          <div class="showTime">10pm - 1am</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/styles/sections.css';

.showContainer {
  align-content: center;
  display: inline-flex;
  justify-content: space-between;
  font-size: 16px;
  margin-top: 30px;
  max-width: 1600px;
  width: 100%;

  .show {
    margin: 20px;
    width: 400px;
  }

  .showName {
    font-size: 35px;
    font-weight: bold;
    margin: 32px 0;
  }

  .showTime {
    margin-top: 5px;
  }
}

@media (max-width: 600px) {
  .upcomingShows {
    .showContainer {
      display: inline-block;

      .show {
        display: inline-block;
        margin: 10px 10px 50px;
        width: 300px;
      }
      .showName {
        font-size: 22px;
        margin: 10px 0;
      }
    }
  }
}
</style>
