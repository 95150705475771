<template>
  <div class="footer">
    <div class="socialRow">
      <a
        href="//soundcloud.com/spicy-deluxe"
        target="_blank"
      >
        <img src="@/assets/soundcloud.svg" alt="soundcloud" />
      </a>
      <a
        href="//instagram.com/itsspicydeluxe"
        target="_blank"
      >
        <img src="@/assets/instagram.svg" alt="instagram" />
      </a>
    </div>
    <div class="contactRow">
      <a href="mailto:spicydeluxedj@gmail.com">
        contact us
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="scss">
.footer {
  background-color: #333;
  padding: 30px;

  .socialRow {
    margin-bottom: 6px;
  }

  a {
    color: #eee;
    text-decoration: none;

    &:hover {
      color: white;
    }

    &:nth-child(n+2) {
      margin-left: 12px;
    }
  }

  img {
    height: 28px;
    width: 28px;
  }
}

@media (max-width: 600px) {
  .contactRow {
    font-size: 14px;
    text-decoration: underline;
  }
}
</style>
