<template>
  <div class="homeImage">
    <div class="homeImageCover"></div>
  </div>
  <!-- <ShowsSection /> -->
  <MixesSection />
  <Footer />
</template>

<script>
import Footer from '../components/Footer.vue';
import MixesSection from './sections/MixesSection.vue';
import ShowsSection from './sections/ShowsSection.vue';

export default {
  name: 'HomeView',
  components: {
    Footer,
    MixesSection,
    ShowsSection,
  },
}
</script>

<style lang="scss">
.homeImage {
  background-image: url("@/assets/IMG_6307.jpg");
  background-size: cover;
  height: 100vh;
  position: relative;
  width: 100%;
}

.homeImageCover {
  background-color: rgba(0, 0, 0, 0.15);
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

@media (max-width: 600px) {
  .homeImage {
    background-position: 50% 0%;
    background-size: cover;
    height: 80vmax;
  }
}
</style>
