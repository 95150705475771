<template>
  <iframe
    width="100%"
    height="200"
    scrolling="no"
    frameborder="no"
    :src="`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${trackId}&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true`">
  </iframe>
</template>

<script>
export default {
  name: 'SoundcloudEmbed',
  props: {
    trackId: {
      type: String,
      default: '454424661',
    },
  },
};
</script>
