<template>
  <div class="mixes section">
    <div class="sectionContent">
      <h1>Mixes</h1>
      <div class="mixEmbedContainer">
        <div class="mixEmbed">
          <SoundcloudEmbed trackId="1285141501" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoundcloudEmbed from '@/components/SoundcloudEmbed.vue';

export default {
  name: 'MixesSection',
  components: {
    SoundcloudEmbed,
  },
};
</script>

<style lang="scss">
@import '@/styles/sections.css';

.mixEmbedContainer {
  margin-top: 100px;
  text-align: center;

  .mixEmbed {
    display: inline-block;
    max-width: 600px;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .mixEmbedContainer {
    margin-top: 50px;
  }
}
</style>
